import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { fontSize, fontFamily, product_sans } from "../../lib/fonts";
import { IconArrowRight10 } from "../../icons-ts";
import { colors } from "../../lib/constant";

const SelectPrice = styled.select`
  width: 100%;
  background: transparent;
  padding: 12px 10px;
  border: 1px solid #eaf0f2;
  border-radius: 6px;
  ${fontSize("15px")}
  ${fontFamily(product_sans.medium)};
  appearance: none;
  position: relative;
  z-index: 3;

  //hide default arrow IE
  &::-ms-expand {
    display: none;
  }
`;

const SelectPriceArrow = styled(IconArrowRight10)`
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -3px;
  transform: rotate(90deg);
`;

const SelectPriceContainer = styled.div`
  position: relative;
`;

interface IDropdownItem {
  label: string | React.ReactNode;
  id: number | string;
}

export const Dropdown = ({
  items,
  onRequestChange,
  selectedItem,
}: {
  items: IDropdownItem[];
  onRequestChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  selectedItem: number | string;
}) => {
  return (
    <SelectPriceContainer>
      <SelectPrice onChange={onRequestChange} defaultValue={selectedItem}>
        {items.map(({ label, id }) => (
          <option key={id} value={id}>
            {label}
          </option>
        ))}
      </SelectPrice>
      <SelectPriceArrow width="6" fill={colors.secondary} />
    </SelectPriceContainer>
  );
};
