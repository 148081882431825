import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { Card } from "./ui/Card";
import { Title } from "./ui/Title";
import { Layout } from "./ui/Layout";
import { Paragraph } from "./ui/Paragraph";
import { mediaQuery } from "react-styled-mediaquery";
import { DottedLink } from "./ui/DottedLink";
import { Link } from "react-scroll";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { colors } from "../lib/constant";
import { fontFamily, fontSize, product_sans } from "../lib/fonts";
import { FormattedMessage } from "react-intl";
import { IconBasic30, IconLove30 } from "../icons-ts";
import IlluOptions from "../../assets/illustrations/options.jpg";

const Container = styled(Row)`
  margin-top: 100px;

  ${mediaQuery("<=", "desktop")`
    margin-top: 40px;
  `}
  ${mediaQuery("<=", "tablet")`
    margin-top: 10px;
  `}
`;

const CardItem = styled(Col)`
  display: flex !important;
`;

const ImgOptions = styled(LazyLoadImage)`
  display: block;
  width: 100%;
`;

const ScrollLink = styled(Link)`
  cursor: pointer;
`;

const Include = styled.div`
  ${fontSize("13px")}
  line-height: 18px;
  ${fontFamily(product_sans.medium)};
  color: ${colors.grey};
`;

const ParagraphStyled = styled(Paragraph)`
  margin-bottom: 60px;

  ${mediaQuery("<=", "tablet")`
    margin-bottom: 30px;
    text-align: center;
  `}
`;
const TitleStyled = styled(Title)`
  ${mediaQuery("<=", "tablet")`
    text-align: center;
  `}
`;

export const SectionOptions = () => {
  return (
    <Layout id="partnerhub">
      <Container between="xs" middle="xs">
        <Col xs={false} md={5} lg={5}>
          <ImgOptions
            src={IlluOptions}
            alt=""
            effect="opacity"
            threshold={300}
          />
        </Col>

        <Col xs={12} md={6} mdOffset={1} lgOffset={1} lg={6}>
          <TitleStyled htmlLevel={2} theme="titleBodyStyle">
            <FormattedMessage
              id="options.title"
              defaultMessage="Get even further with Partner Hub"
            />
          </TitleStyled>

          <ParagraphStyled>
            <FormattedMessage
              id="options.desc"
              defaultMessage="Partner Hub is a simple, yet complete, ready to use solution that allows each partner to fill in their data and interact with your CRM."
            />
          </ParagraphStyled>
          <Row>
            <CardItem xs={12} sm={6}>
              <Card
                icon={<IconBasic30 width="30" fill={colors.primary} />}
                label={
                  <FormattedMessage
                    id="options.firstItem.label"
                    defaultMessage="The basics"
                  />
                }
                desc={
                  <FormattedMessage
                    id="options.firstItem.desc"
                    defaultMessage="Tools to update all their
                  contact information, location and opening hours, ..."
                  />
                }
              >
                <ScrollLink
                  to="price"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-100}
                >
                  <Include>
                    <FormattedMessage
                      id="options.item.include"
                      defaultMessage="Included in full plan"
                    />
                  </Include>
                </ScrollLink>
              </Card>
            </CardItem>

            <CardItem xs={12} sm={6}>
              <Card
                icon={<IconLove30 width="30" fill={colors.primary} />}
                label={
                  <FormattedMessage
                    id="options.secondItem.label"
                    defaultMessage="Cherry on top"
                  />
                }
                desc={
                  <FormattedMessage
                    id="options.secondItem.desc"
                    defaultMessage="We can provide on
                  premises services to go
                  even further..."
                  />
                }
              >
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-100}
                >
                  <DottedLink fontsize={"13px"}>
                    <FormattedMessage
                      id="options.item.contact"
                      defaultMessage="Contactez-nous"
                    />
                  </DottedLink>
                </Link>
              </Card>
            </CardItem>
          </Row>
        </Col>
      </Container>
    </Layout>
  );
};
