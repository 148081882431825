import React from "react";
import styled from "styled-components";
import { colors } from "../../lib/constant";
import { mediaQuery } from "react-styled-mediaquery";
import { fontSize, fontFamily, product_sans } from "../../lib/fonts";
import { Paragraph } from "./Paragraph";
import { Media } from "../../lib/media";
import { CardMobile } from "./CardMobile";

const ContainerCard = styled.div`
  background-color: ${colors.greyLt};
  width: 100%;
  border-radius: 15px;
  padding: 40px 30px;
  text-align: center;
  position: relative;

  ${mediaQuery("<=", "tablet")`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    text-align: left;
    padding: 25px;
  `}
`;

export const Label = styled.div`
  color: ${colors.greyDrk};
  ${fontFamily(product_sans.medium)};
  ${fontSize("15px")};
  line-height: 20px;
  margin-bottom: 5px;

  ${mediaQuery("<=", "tablet")`
    margin-bottom: 0;
  `}
`;

export const IconCard = styled.div`
  margin-bottom: 30px;

  ${mediaQuery("<=", "tablet")`
    margin: 0 20px 0 0;
  `}
`;

const PriceContainer = styled.div`
  ${fontSize("12px")};
  ${fontFamily(product_sans.medium)};
`;

const Price = styled.span`
  ${fontSize("18px")}
`;

const TextCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MoreInfoText = styled.div`
  margin-top: auto;
  padding-top: 30px;
  ${fontFamily(product_sans.medium)};

  ${mediaQuery("<=", "tablet")`
    padding-top: 15px;
  `}
`;

const ContainerCardDesktop = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${mediaQuery("<=", "tablet")`
    display: none;
  `}
`;

const Text = styled.span`
  ${fontFamily(product_sans.regular)};
`;

export const PriceCard = ({
  price,
  className,
}: {
  price: number;
  className?: string;
}) => (
  <PriceContainer className={className}>
    <Text>Àpd</Text> <Price>{price}€</Price> /mois
  </PriceContainer>
);

export const Card = ({
  icon,
  iconQuestion,
  label,
  desc,
  children,
}: {
  icon: React.ReactNode;
  iconQuestion?: React.ReactNode;
  label: React.ReactNode;
  desc: React.ReactNode;
  children?: React.ReactNode;
}) => (
  <ContainerCard>
    {iconQuestion}

    <ContainerCardDesktop>
      <IconCard>{icon}</IconCard>
      <TextCard>
        <div>
          <Label>{label}</Label>
          <Paragraph size="13px" lineHeight="22px">
            {desc}
          </Paragraph>
        </div>

        {children && <MoreInfoText>{children}</MoreInfoText>}
      </TextCard>
    </ContainerCardDesktop>

    <Media when={"<=768"}>
      <CardMobile icon={icon} label={label} children={children} desc={desc} />
    </Media>
  </ContainerCard>
);
