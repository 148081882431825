import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-styled-flexboxgrid";
import { Layout } from "./ui/Layout";
import { Button } from "./ui/Button";
import { colors } from "../lib/constant";
import { IconArrowR16 } from "../icons-ts";
import { fontSize } from "../lib/fonts";
import { mediaQuery } from "react-styled-mediaquery";
import { Link } from "react-scroll";
import { Title } from "./ui/Title";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FormattedMessage } from "react-intl";
import logo from "../../assets/logo.svg";
import illustrationHeader from "../../assets/illustrations/header.png";
import { languagesSelectorLabels, useLocale } from "../lib/MainProvider";
import { SelectMobile } from "../components/ui/Select";

export const HeaderIntro = () => {
  const { locale, setLocale } = useLocale();
  return (
    <Container>
      <Row middle="xs" between="xs">
        <IntroContainer xs={12} md={5} lg={5}>
          <IntroContainerTop>
            <LogoMobile src={logo} alt="Partner Locator" height="40" />

            {typeof window !== "undefined" && (
              <LanguageSelectorMobile>
                <SelectMobile
                  items={languagesSelectorLabels.map((item) => ({
                    label: item.labelShort,
                    value: item.value,
                  }))}
                  value={locale}
                  onChange={(e) => {
                    setLocale(e.currentTarget.value as any);
                  }}
                />
              </LanguageSelectorMobile>
            )}
          </IntroContainerTop>

          <Title htmlLevel={1} theme="titleHeaderStyle">
            <FormattedMessage
              id="intro.title"
              defaultMessage="Intuitive store locator straight from your CRM"
            />
          </Title>

          <HeaderParagraph>
            <FormattedMessage
              id="intro.desc"
              defaultMessage="Partner Locator is the easiest solution to display intuitive maps on
      your websites & campaigns, connected in real-time with best in class
      mapping system Google Maps and your Clixray CRM."
            />
          </HeaderParagraph>

          <Link
            href="#"
            to="features"
            smooth={true}
            duration={700}
            offset={-80}
          >
            <Button icon={<IconArrowR16 width="16" fill="#FFF" />}>
              <FormattedMessage
                id="intro.buttonLabel"
                defaultMessage="Learn more"
              />
            </Button>
          </Link>
        </IntroContainer>

        <Col xs={false} sm={false} md={6} lg={6}>
          <ImgIntro
            width="545"
            src={illustrationHeader}
            alt="illustration"
            visibleByDefault={true}
          />
        </Col>
      </Row>
    </Container>
  );
};

const Container = styled(Layout)`
  padding-top: 80px;

  ${mediaQuery("<=", "desktop")`
    padding-top: 30px;
  `}
`;

const IntroContainer = styled(Col)`
  color: #fff;

  ${mediaQuery("<=", "tablet")`
    text-align: center;
  `}
`;

const HeaderParagraph = styled.div`
  color: ${colors.greyDrk};
  margin-bottom: 40px;
  ${fontSize("15px")};
  line-height: 30px;

  ${mediaQuery("<=", "phablet")`
    display: none;
  `}
`;

const LogoMobile = styled.img`
  margin: 0 auto 30px;
  display: none;

  ${mediaQuery("<=", "tablet")`
    display: block;
    margin: 0 15px;
  `}
`;

const ImgIntro = styled(LazyLoadImage)`
  width: 100%;
  display: block;
`;

const IntroContainerTop = styled.div`
  ${mediaQuery("<=", "tablet")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  `}
`;

const LanguageSelectorMobile = styled.div`
  ${mediaQuery(">", "tablet")`
    display: none;
  `}
`;
