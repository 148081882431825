import React, { useRef } from "react";
import styled from "styled-components";
import { HeaderNavigation } from "./HeaderNavigation";
import { HeaderIntro } from "./HeaderIntro";
import useWindowScrollPosition from "@rehooks/window-scroll-position";
import { motion } from "framer-motion";
import { Media } from "../lib/media";
import { Separator } from "./ui/Separator";
import mapBg from "../../assets/map.jpg";

const HeaderStyle = styled.div`
  position: relative;
  width: 100%;
  background: url(${mapBg}) no-repeat top center,
    linear-gradient(180deg, #fffcfd 0%, #fff 100%);
  background-size: 2056px 743px;
`;

const HeaderFixe = styled(motion.div)`
  top: -142px;
  position: fixed;
  width: 100%;
  z-index: 99;
`;

export const Header = () => {
  const position =
    typeof window !== "undefined" ? useWindowScrollPosition() : { y: 0, x: 0 };
  const headerRef = useRef<any>(null);
  const navRef = useRef<any>(null);

  const headerHeight = headerRef.current && headerRef.current.offsetHeight;
  const navHeight = navRef.current && navRef.current.offsetHeight;
  const isOpen = position.y > headerHeight;

  const HeaderAnimation = {
    open: { top: 0 },
    closed: { top: -navHeight || -142 },
  };

  return (
    <>
      <Media when={">768"}>
        <HeaderFixe
          animate={isOpen ? "open" : "closed"}
          variants={HeaderAnimation}
          initial={"closed"}
          ref={navRef}
        >
          <HeaderNavigation fixed={true} />
        </HeaderFixe>
      </Media>

      <HeaderStyle id="header" ref={headerRef}>
        <Media when={">768"}>
          <HeaderNavigation />
        </Media>
        <HeaderIntro />
      </HeaderStyle>
      <Separator />
    </>
  );
};
