import React from "react";
import { Paragraph } from "./Paragraph";
import { IconCard, Label, MoreInfoText } from "./Card";

export const CardMobile = ({
  icon,
  label,
  desc,
  children,
}: {
  icon: React.ReactNode;
  label: React.ReactNode;
  desc: React.ReactNode;
  children?: React.ReactNode;
}) => (
  <table>
    <tbody>
      <tr>
        <td>
          <IconCard>{icon}</IconCard>
        </td>
        <td>
          <Label>{label}</Label>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <Paragraph size="14px" lineHeight="20px">
            {desc}
          </Paragraph>
          {children && <MoreInfoText>{children}</MoreInfoText>}
        </td>
      </tr>
    </tbody>
  </table>
);
