import React from "react";
import { Layout } from "./ui/Layout";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { Button } from "./ui/Button";
import { Title } from "./ui/Title";
import { Paragraph } from "./ui/Paragraph";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FormattedMessage } from "react-intl";
import IlluContact from "../../assets/contact.png";

const ImgContact = styled(LazyLoadImage)`
  width: 100%;
  display: block;
`;

const ContactContainer = styled(Row)`
  text-align: center;
`;

export const SectionContact = () => {
  return (
    <>
      <Layout id="contact">
        <ContactContainer around="xs" middle="xs" center="xs">
          <Col xs={12} sm={8} md={5} lg={5}>
            <Title htmlLevel={2} theme="titleBodyStyle">
              <FormattedMessage
                id="contact.title"
                defaultMessage="Interested or convinced ?"
              />
            </Title>

            <Paragraph style={{ marginBottom: 30 }}>
              <FormattedMessage
                id="contact.desc"
                defaultMessage="Contact us to obtain a free demo or for any information regarding Partner Locator."
              />
            </Paragraph>

            <a href="mailto:info@igloo.be?subject=Partner Locator">
              <Button secondary>
                <FormattedMessage
                  id="contact.buttonLabel"
                  defaultMessage="Contact us"
                />
              </Button>
            </a>
          </Col>
          <Col xs={false} sm={false} md={5} lg={5}>
            <ImgContact
              src={IlluContact}
              alt="Illustation contact"
              effect="opacity"
              threshold={300}
            />
          </Col>
        </ContactContainer>
      </Layout>
    </>
  );
};
