import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { Title } from "./ui/Title";
import { Layout } from "./ui/Layout";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FormattedMessage } from "react-intl";
import { Paragraph } from "./ui/Paragraph";
import { mediaQuery } from "react-styled-mediaquery";
import IlluApi from "../../assets/illustrations/api.png";

const ImgOptions = styled(LazyLoadImage)`
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
`;

const SectionApiTitle = styled.div`
  text-align: center;
  width: 70%;
  margin: 0 auto 80px auto;

  ${mediaQuery("<=", "tablet")`
    width: 100%;
    margin-bottom: 30px;
  `}
`;

export const SectionApi = () => {
  return (
    <Layout id="options">
      <Row center="sm">
        <Col xs={12} sm={10}>
          <SectionApiTitle>
            <Title htmlLevel={2} theme="titleBodyStyle">
              <FormattedMessage
                id="api.title"
                defaultMessage="Real-time data driven"
              />
            </Title>
            <Paragraph>
              <FormattedMessage
                id="api.desc"
                defaultMessage="Partner Locator favors real-time data by connecting your CRM in real time through a high performance layers of API. On top, we can propose connectors to fetch data from almost anywhere..."
              />
            </Paragraph>
          </SectionApiTitle>

          <ImgOptions src={IlluApi} alt="" effect="opacity" threshold={300} />
        </Col>
      </Row>
    </Layout>
  );
};
