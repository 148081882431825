import React from "react";
import { Header } from "../components/Header";
import { SectionFeatures } from "../components/SectionFeatures";
import { GlobalStyles } from "../components/ui/GlobalStyles";
import { SectionPrice } from "../components/SectionPrice";
import { SectionContact } from "../components/SectionContact";
import { SectionOptions } from "../components/SectionOptions";
import { SectionApi } from "../components/SectionApi";
import { SectionFooter } from "../components/SectionFooter";
import { Helmet } from "react-helmet";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../lib/constant";
import { mediaQuery } from "react-styled-mediaquery";
import { CardDevices } from "../components/ui/CardDevices";
import { Layout } from "../components/ui/Layout";
import { Separator } from "../components/ui/Separator";
import { fontSize } from "../lib/fonts";
import { FormattedMessage } from "react-intl";
import imageUrlScreensMap from "../../assets/screens/map.jpg";
import imageUrlScreensInfo from "../../assets/screens/info.jpg";
import imageUrlScreensList from "../../assets/screens/list.jpg";
import imageUrlScreensStock from "../../assets/screens/stock.png";
import imageUrlScreensStyles from "../../assets/screens/styles.jpg";
import imageUrlScreensSearch from "../../assets/screens/search.jpg";
import imageUrlScreensPoints from "../../assets/screens/points.jpg";

const Index = () => {
  return (
    <>
      <Helmet>
        <html lang="fr" />
        <title>
          Partner Locator, Intuitive store locator straight from your CRM
        </title>
        <meta
          name="title"
          content="Partner Locator, Intuitive store locator straight from your CRM"
        />
        <meta
          name="keywords"
          content="Partner Locator, Intuitive store locator straight from your CRM."
        />
        <meta
          name="description"
          content="Partner Locator is the easiest solution to display intuitive maps on your websites & campaigns, connected in real-time with best in class mapping system Google Maps and your Clixray CRM."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        ></meta>
        <meta name="application-name" content="Partner Locator" />
        <meta name="author" content="Igloo" />
      </Helmet>

      <GlobalStyles />

      <ThemeProvider theme={theme}>
        <Header />
        <SectionFeatures
          items={[
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.embededMaps.title"
                      defaultMessage="Embeded maps"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.embededMaps.desc"
                    defaultMessage="Embed maps on any website or campaign by a simple copy/paste of 2 lines of code."
                  />
                </Desc>
              ),
              imageUrl: imageUrlScreensMap,
            },
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.info.title"
                      defaultMessage="Detailed information"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.info.desc"
                    defaultMessage="Choose the information you want to display amongst logo, address, phone, fax, e-mail, website, social links, open hours and partner type."
                  />
                </Desc>
              ),
              imageUrl: imageUrlScreensInfo,
            },
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.list.title"
                      defaultMessage="List or maps"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.list.desc"
                    defaultMessage="Toggle a list mode along the map view in order to make it easier to find the most accurate partner."
                  />
                </Desc>
              ),
              imageUrl: imageUrlScreensList,
            },
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.stock.title"
                      defaultMessage="Stock levels"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.stock.desc"
                    defaultMessage="Keep your customers informed in real time of your products in store availabilities."
                  />
                </Desc>
              ),
              imageUrl: imageUrlScreensStock,
            },
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.mapStyles.title"
                      defaultMessage="Map styles"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.mapStyles.desc"
                    defaultMessage="Change the settings of the map amongst style, zoom level, default center and much more."
                  />
                </Desc>
              ),
              imageUrl: imageUrlScreensStyles,
            },
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.search.title"
                      defaultMessage="Intuitive search bar"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.search.desc"
                    defaultMessage="Activate the intuitive search bar which allow double search in both Google Maps index of cities and your partner names."
                  />
                </Desc>
              ),
              imageUrl: imageUrlScreensSearch,
            },
          ]}
        />

        <Layout>
          <CardDevices />
        </Layout>

        <SectionFeatures
          reverse
          items={[
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.segmentation.title"
                      defaultMessage="Segmentation"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.segmentation.desc"
                    defaultMessage="Display partners based on deal campaigns or partner groups."
                  />
                </Desc>
              ),
              imageUrl: imageUrlScreensPoints,
            },
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.customizedStyle.title"
                      defaultMessage="Customized style"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.customizedStyle.desc"
                    defaultMessage="Change markers for each type of partners, switch colors and add legend to make your maps more attractive !"
                  />
                </Desc>
              ),
              imageUrl: imageUrlScreensPoints,
            },
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.cta.title"
                      defaultMessage="Call to action"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.cta.desc"
                    defaultMessage="Define the call to action button to activate your campaigns straight from the map."
                  />
                </Desc>
              ),
              imageUrl: imageUrlScreensPoints,
            },
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.dataQuality.title"
                      defaultMessage="Data quality"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.dataQuality.desc"
                    defaultMessage="Smart tools which allows batch update of the missing
                      geolocation of partners and track the wrong ones."
                  />
                </Desc>
              ),
              imageUrl: imageUrlScreensPoints,
            },
          ]}
        />
        <Separator />

        <SectionApi />

        <Separator />

        <SectionOptions />

        <Separator />

        <SectionPrice />

        <SectionContact />

        <SectionFooter />
      </ThemeProvider>
    </>
  );
};

export default Index;

const Desc = styled.div`
  ${fontSize("15px")};
  line-height: 25px;
  padding-top: 10px;
  padding-bottom: 30px;

  ${mediaQuery("<=", "tablet")`margin-bottom: 30px;`}
`;
