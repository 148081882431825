import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Layout } from "./ui/Layout";
import { mediaQuery } from "react-styled-mediaquery";
import { Title } from "./ui/Title";
import { Paragraph } from "./ui/Paragraph";
import { Link } from "react-scroll";
import { fontFamily, fontSize, product_sans } from "../lib/fonts";
import { colors } from "../lib/constant";
import { IconCheck20, IconCross20 } from "../icons-ts";
import { Media } from "../lib/media";
import { Dropdown } from "./ui/Dropdown";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { DottedLink } from "./ui/DottedLink";
import { Tooltip } from "./ui/Tooltip";

const messages = defineMessages({
  pricingTitleFull: {
    id: "pricing.title.full",
    defaultMessage: "Full",
  },
});

export interface TablePriceType {
  key: string;
  title: string;
  dossier?: string;
  prixDossier?: string;
  prixMois?: string;
}

interface IFeature {
  label: React.ReactNode;
  values: [{ standard: React.ReactNode; full: React.ReactNode }];
}

export const SectionPrice = () => {
  const [currentPlan, setCurrentPlan] = useState<string>("standard");
  const { formatMessage } = useIntl();

  function changeValueSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    setCurrentPlan(e.currentTarget.value);
  }

  const plans = [
    {
      label: "Standard",
      price: 260,
      id: "standard",
    },
    {
      label: formatMessage(messages.pricingTitleFull),
      price: 650,
      id: "full",
    },
  ];

  return (
    <LayoutCustom id="price">
      <SectionPriceTitle>
        <Title htmlLevel={2} theme="titleBodyStyle">
          <FormattedMessage
            id="pricing.title"
            defaultMessage="Simple & transparent pricing"
          />
        </Title>
        <Paragraph>
          <FormattedMessage
            id="pricing.desc"
            defaultMessage="Partner locator comes in two very affordable fees : Standard or Full, wether you have your own Google Maps account."
          />
        </Paragraph>
      </SectionPriceTitle>

      <Media when={"<=768"}>
        <Dropdown
          items={plans}
          onRequestChange={changeValueSelect}
          selectedItem={currentPlan}
        />
      </Media>

      <Table className={currentPlan === "full" ? "hide-standard" : "hide-full"}>
        <tbody>
          <tr>
            <td>&nbsp;</td>
            {plans.map(({ label, price }, i) => (
              <td colSpan={2} key={i}>
                <Plan>{label}</Plan>
                <PriceContainer>
                  <Price>
                    {price}
                    <sup>€</sup>
                  </Price>
                  <Month>
                    /
                    <FormattedMessage
                      id="pricing.month"
                      defaultMessage="mois"
                    />
                  </Month>
                </PriceContainer>
              </td>
            ))}
          </tr>
          {features.map(({ label, values }, i) => (
            <tr key={i}>
              <td>
                <Label>{label}</Label>
              </td>
              {values.map(({ standard, full }, j) => (
                <Fragment key={j}>
                  <td colSpan={2}>{standard}</td>
                  <td colSpan={2}>{full}</td>
                </Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <Tva>
        <FormattedMessage
          id="pricing.vat"
          defaultMessage="Tous les prix s'entendent Hors TVA"
        />
      </Tva>
    </LayoutCustom>
  );
};

const LayoutCustom = styled(Layout)`
  padding-bottom: 150px;

  ${mediaQuery("<=", "tablet")`
    padding-bottom: 50px;
  `}
`;

const SectionPriceTitle = styled.div`
  text-align: center;
  width: 70%;
  margin: 0 auto 80px auto;

  ${mediaQuery("<=", "tablet")`
    width: 100%;
    margin-bottom: 30px;
  `}
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  position: relative;

  ${mediaQuery("<=", "tablet")`
    margin-top: 30px;

    > tr:first-child td:first-child { display: none; }
  `}

  ${mediaQuery("<=", "tablet")`
    &.hide-full tr td:last-child {
      display: none;
    }

    &.hide-standard tr td:nth-child(2) { display: none; }
  `}

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    width: 100%;
    z-index: -1;
    box-shadow: 0 5px 30px 0 rgba(27, 22, 51, 0.05);
    border-radius: 10px;

    ${mediaQuery(">", "tablet")`
      width: calc(100% - 250px);
    `}
  }

  tr > td:not(:first-child) {
    text-align: center;
  }

  tr > td {
    box-sizing: border-box;

    &:first-child {
      ${mediaQuery(">", "tablet")`
        width: 250px;
    `}
    }
  }

  tr:not(:first-child) td {
    padding-top: 30px;

    ${mediaQuery("<=", "tablet")`
      padding-left: 30px;
      padding-right: 30px;
    `}
  }

  tr:first-child td {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid ${colors.greyLt};
  }

  tr:last-child td {
    padding-bottom: 60px;

    ${mediaQuery("<=", "tablet")`padding-bottom: 30px;`}
  }

  tr:nth-child(2) td {
    padding-top: 60px;
  }
`;

const Plan = styled.div`
  ${fontSize("11px")};
  ${fontFamily(product_sans.bold)};
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${colors.primary};
  margin-bottom: 15px;

  ${mediaQuery("<=", "tablet")`
    text-align: center;
  `}
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
`;

const Price = styled.div`
  ${fontSize("30px")};
  ${fontFamily(product_sans.bold)};
  line-height: 37px;
  color: ${colors.greyDrk};

  sup {
    ${fontSize("15px")};
  }
`;

const Label = styled.div`
  ${fontSize("13px")};
  ${fontFamily(product_sans.regular)};
  line-height: 15px;
`;
const Month = styled.div`
  ${fontSize("12px")};
  ${fontFamily(product_sans.regular)};
  line-height: 15px;
`;

const features: IFeature[] = [
  {
    label: (
      <Link to="features" spy={true} smooth={true} duration={700} offset={-150}>
        <DottedLink>
          <FormattedMessage
            id="pricing.label.allFunc"
            defaultMessage="All functionalities"
          />
        </DottedLink>
      </Link>
    ),
    values: [
      {
        standard: <IconCheck20 fill={colors.green} width="20" height="20" />,
        full: <IconCheck20 fill={colors.green} width="20" height="20" />,
      },
    ],
  },
  {
    label: (
      <FormattedMessage
        id="pricing.label.portalOption"
        defaultMessage="Partner Hub"
      />
    ),
    values: [
      {
        standard: <IconCross20 fill={colors.red} width="20" height="20" />,
        full: <IconCheck20 fill={colors.green} width="20" height="20" />,
      },
    ],
  },
  {
    label: (
      <FormattedMessage
        id="pricing.label.portalWhitelabel"
        defaultMessage="Whitelabel"
      />
    ),
    values: [
      {
        standard: <IconCross20 fill={colors.red} width="20" height="20" />,
        full: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Label style={{ marginRight: 5 }}>50 €</Label>
            <Month>
              /<FormattedMessage id="pricing.month" defaultMessage="mois" />
            </Month>
          </div>
        ),
      },
    ],
  },
  {
    label: (
      <FormattedMessage id="pricing.label.setup" defaultMessage="Setup cost" />
    ),
    values: [
      {
        standard: <Label>0,00€</Label>,
        full: <Label>0,00€</Label>,
      },
    ],
  },
  {
    label: (
      <FormattedMessage
        id="pricing.label.unlimited"
        defaultMessage="Unlimited use"
      />
    ),
    values: [
      {
        standard: <IconCheck20 fill={colors.green} width="20" height="20" />,
        full: <IconCheck20 fill={colors.green} width="20" height="20" />,
      },
    ],
  },
  {
    label: (
      <FormattedMessage
        id="pricing.label.office"
        defaultMessage="Office hours"
      />
    ),
    values: [
      {
        standard: <IconCheck20 fill={colors.green} width="20" height="20" />,
        full: <IconCheck20 fill={colors.green} width="20" height="20" />,
      },
    ],
  },
  {
    label: (
      <div style={{ display: "flex", alignItems: "center", paddingRight: 30 }}>
        <span style={{ marginTop: 2 }}>
          <FormattedMessage
            id="pricing.label.countries"
            defaultMessage="Additional country"
          />
        </span>
        <div style={{ marginLeft: "auto" }}>
          <div style={{ marginLeft: 10 }}>
            <Tooltip
              tooltipMessage={
                <FormattedMessage
                  id="pricing.label.countries.tooltip"
                  defaultMessage="Par défaut, vous devez utiliser votre propre compte Google Maps, mais il est également possible d’utiliser notre compte si c’est plus simple pour vous."
                />
              }
            />
          </div>
        </div>
      </div>
    ),
    values: [
      {
        standard: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Label style={{ marginRight: 5 }}>175 €</Label>
            <Month>
              /
              <FormattedMessage id="pricing.country" defaultMessage="country" />
            </Month>
          </div>
        ),
        full: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Label style={{ marginRight: 5 }}>525 €</Label>
            <Month>
              /
              <FormattedMessage id="pricing.country" defaultMessage="country" />
            </Month>
          </div>
        ),
      },
    ],
  },
  {
    label: (
      <div style={{ display: "flex", alignItems: "center", paddingRight: 30 }}>
        <span style={{ marginTop: 2 }}>
          <FormattedMessage
            id="pricing.label.gMap"
            defaultMessage="Google Maps"
          />
        </span>
        <div style={{ marginLeft: "auto" }}>
          <div style={{ marginLeft: 10 }}>
            <Tooltip
              tooltipMessage={
                <FormattedMessage
                  id="pricing.label.gMap.tooltip"
                  defaultMessage="Par défaut, vous devez utiliser votre propre compte Google Maps, mais il est également possible d’utiliser notre compte si c’est plus simple pour vous."
                />
              }
            />
          </div>
        </div>
      </div>
    ),
    values: [
      {
        standard: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Label style={{ marginRight: 5 }}>125 €</Label>
            <Month>
              /<FormattedMessage id="pricing.month" defaultMessage="mois" />
            </Month>
          </div>
        ),
        full: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Label style={{ marginRight: 5 }}>125 €</Label>
            <Month>
              /<FormattedMessage id="pricing.month" defaultMessage="mois" />
            </Month>
          </div>
        ),
      },
    ],
  },
  {
    label: (
      <div style={{ display: "flex", alignItems: "center", paddingRight: 30 }}>
        <span style={{ marginTop: 2 }}>
          <FormattedMessage
            id="pricing.label.connectors"
            defaultMessage="Connectors"
          />
        </span>
        <div style={{ marginLeft: "auto" }}>
          <div style={{ marginLeft: 10 }}>
            <Tooltip
              tooltipMessage={
                <FormattedMessage
                  id="pricing.label.connectors.tooltip"
                  defaultMessage="If you don’t have an API layer over your data, we can plug all sorts of connectors to fetch your partner’s data: JSON, XML, Excel, CSV, One Nav, Set to Sell, SFTP, AWS S3, MS Azure,..."
                />
              }
            />
          </div>
        </div>
      </div>
    ),
    values: [
      {
        standard: (
          <Link
            style={{ fontSize: 13 }}
            to="contact"
            spy={true}
            smooth={true}
            duration={700}
            offset={-150}
          >
            <DottedLink>
              <FormattedMessage
                id="pricing.label.contact"
                defaultMessage="Contacts us"
              />
            </DottedLink>
          </Link>
        ),
        full: (
          <Link
            style={{ fontSize: 13 }}
            to="contact"
            spy={true}
            smooth={true}
            duration={700}
            offset={-150}
          >
            <DottedLink>
              <FormattedMessage
                id="pricing.label.contact"
                defaultMessage="Contacts us"
              />
            </DottedLink>
          </Link>
        ),
      },
    ],
  },
];

const Tva = styled.div`
  text-align: center;
  ${fontSize("13px")};
  ${fontFamily(product_sans.regular)};
  line-height: 15px;
  margin-top: 30px;
  color: ${colors.grey};

  ${mediaQuery(">", "tablet")`
    margin-left: 250px;
  `}
`;
