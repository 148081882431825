import React, { useState } from "react";
import { Layout } from "./ui/Layout";
import { SectionFeaturesList } from "./SectionFeaturesList";
interface IFeatureItem {
  label: React.ReactNode;
  desc?: React.ReactNode;
  imageUrl?: any;
}

export const SectionFeatures = ({
  items,
  reverse,
}: {
  items: IFeatureItem[];
  reverse?: boolean;
}) => {
  const [activeElementIndex, setActiveElementIndex] = useState(0);

  return (
    <>
      <Layout>
        <SectionFeaturesList
          reverse={reverse}
          onItemClick={(index) => setActiveElementIndex(index)}
          items={items.map((item, index) => ({
            ...item,
            active: activeElementIndex === index,
          }))}
        />
      </Layout>
    </>
  );
};
