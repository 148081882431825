import React, { ReactNode } from "react";
import styled from "styled-components";
import { colors } from "../../lib/constant";

interface IconQuestionProps {
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const Container = styled.div<IconQuestionProps>`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.primary};
  border-radius: 100%;
  z-index: 10;

  &:hover {
    cursor: ${(props) => (props.onClick ? `pointer` : `help`)};
  }
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const WrapIf = ({
  condition,
  Wrapper,
  children,
}: {
  condition: boolean;
  Wrapper: React.ComponentType;
  children: ReactNode;
}): JSX.Element => {
  if (condition) {
    return <Wrapper>{children}</Wrapper>;
  } else {
    return <>{children}</>;
  }
};

export const TooltipIconBox = React.forwardRef(
  (
    {
      children,
      absolute,
      ...props
    }: {
      children: React.ReactNode;
      absolute?: boolean;
    } & IconQuestionProps,
    ref,
  ) => (
    <WrapIf condition={!!absolute} Wrapper={AbsoluteContainer}>
      <Container {...props} ref={ref as any}>
        {children}
      </Container>
    </WrapIf>
  ),
);
