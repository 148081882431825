import React, { useState, ReactNode, useEffect, useRef } from "react";
import { colors } from "../../lib/constant";
import { IconQuestion20 } from "../../icons-ts";
import styled from "styled-components";
import { Manager, Reference, Popper } from "react-popper";
import { motion } from "framer-motion";
import { TooltipIconBox, AbsoluteContainer, WrapIf } from "./TooltipIconBox";
import { fontSize } from "../../lib/fonts";

const TooltipArrow = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7.5px 0 7.5px;
  border-color: ${colors.greyDrk} transparent transparent transparent;
  position: absolute;
  bottom: -5px;
`;

const AnimationTooltip = styled(
  React.forwardRef(
    (
      {
        visible,
        ...rest
      }: {
        visible: boolean;
        children: ReactNode;
        style: React.CSSProperties;
      },
      ref,
    ) => (
      <motion.div
        animate={
          visible
            ? { bottom: 15, visibility: "visible", opacity: 1 }
            : { bottom: 0, opacity: 0, transitionEnd: { visibility: "hidden" } }
        }
        transition={{
          duration: 0.1,
          ease: "easeInOut",
        }}
        initial={false}
        ref={ref as any}
        {...rest}
      />
    ),
  ),
)`
  background: ${colors.greyDrk};
  padding: 15px;
  color: #fff;
  max-width: 230px;
  border-radius: 10px;
  white-space: pre-line;
  ${fontSize("14px")};
  z-index: 10;
`;

const TooltipDependencies = ({
  dependencies,
  update,
  delay = 700,
}: {
  dependencies: React.DependencyList;
  update: () => void;
  delay?: number;
}) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      update();
    }, delay);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, dependencies);
  return null;
};

export const Tooltip = ({
  tooltipMessage,
  dependencies,
  absolute,
}: {
  tooltipMessage?: string | React.ReactNode;
  absolute?: boolean;
  dependencies?: React.DependencyList;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Manager>
      <WrapIf condition={!!absolute} Wrapper={AbsoluteContainer}>
        <Reference>
          {({ ref }) => (
            <TooltipIconBox
              ref={ref}
              onMouseEnter={() => setIsVisible(true)}
              onMouseLeave={() => setIsVisible(false)}
            >
              <IconQuestion20 fill="#fff" width="7" height="10" />
            </TooltipIconBox>
          )}
        </Reference>
      </WrapIf>

      <Popper placement="top">
        {({ ref, style, arrowProps, forceUpdate }) => (
          <AnimationTooltip visible={isVisible} ref={ref} style={style}>
            {tooltipMessage}
            <TooltipArrow ref={arrowProps.ref} style={arrowProps.style} />
            {dependencies && (
              <TooltipDependencies
                update={forceUpdate}
                dependencies={dependencies}
              />
            )}
          </AnimationTooltip>
        )}
      </Popper>
    </Manager>
  );
};
