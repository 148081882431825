import React, { Fragment, ReactNode } from "react";
import styled from "styled-components";
import { colors } from "../lib/constant";
import { product_sans, fontFamily, fontSize } from "../lib/fonts";
import { mediaQuery } from "react-styled-mediaquery";
import { Media } from "../lib/media";
import { Col, Row } from "react-styled-flexboxgrid";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FormattedMessage } from "react-intl";
interface LiProps {
  active?: boolean;
}

interface IFeatureListItem {
  label: React.ReactNode;
  active: boolean;
  desc?: React.ReactNode;
  imageUrl?: any;
}

const FeatureListContainer = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  position: relative;

  &::before {
    content: "";
    width: 1px;
    background: ${colors.greyLt};
    position: absolute;
    top: 0;
    bottom: 0;
  }
`;

const FeatureListlink = styled.span<LiProps>`
  padding: 11px 30px;
  ${fontFamily(product_sans.medium)};
  color: ${(props) => (props.active ? `${colors.greyDrk}` : `${colors.grey}`)};
  ${fontSize("24px")}
  display: block;
  cursor: pointer;
  transition: color 0.2s;

  ${mediaQuery("<=", "tablet")`
    padding: 20px;
    ${fontSize("20px")}
  `}
`;

const FeatureList = styled.li<LiProps>`
  border-left: ${(props) => props.active && `4px solid ${colors.primary}`};
  transition: border-left 0.2s;

  &:hover {
    border-left: 4px solid ${colors.primary};
  }

  &:hover ${FeatureListlink} {
    color: ${colors.primary};
  }
`;

const DescriptionText = styled.div`
  color: ${colors.greyDrk};
  padding-left: 30px;
  ${fontSize("14px")};
  line-height: 22px;
  border-left: 1px solid ${colors.greyLt};

  ${mediaQuery("<=", "tablet")`
  border-left: 1px solid transparent;
  `}
`;

const ImageContainer = styled(Media)`
  position: relative;
  width: 100%;
  height: 464px;
`;

const FeatureListImage = styled(LazyLoadImage)`
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  display: block;
`;

const FeatureListImageMobile = styled(FeatureListImage)`
  margin: 15px 0;
`;

const ListImageAnimation = ({
  visible,
  src,
  alt,
  noAnimation,
  ...rest
}: {
  visible: boolean;
  src?: any;
  alt: string;
  noAnimation?: boolean;
}) => (
  <motion.div
    style={{
      position: "absolute",
      width: "100%",
      height: "100%",
    }}
    animate={noAnimation || visible ? { opacity: 1 } : { opacity: 0 }}
    initial={false}
    {...rest}
  >
    <FeatureListImage src={src} alt={alt} visibleByDefault={true} />
  </motion.div>
);

const ListTextAnimation = ({
  visible,
  ...rest
}: {
  visible: boolean;
  children: ReactNode;
}) => (
  <motion.div
    style={{ overflow: "hidden" }}
    animate={
      visible ? { height: "auto", opacity: 1 } : { height: 0, opacity: 0 }
    }
    initial={false}
    {...rest}
  />
);

const ColFeature = styled(Col)`
  display: flex !important;
  flex-direction: column;
`;

export const SectionFeaturesList = ({
  items,
  onItemClick,
  reverse,
}: {
  items: IFeatureListItem[];
  onItemClick: (i: number) => void;
  reverse?: boolean;
}) => {
  return (
    <>
      <Row between="xs" middle="xs" id="features">
        {reverse ? (
          <>
            <ColFeature id="featuresMore" xs={12} sm={5} md={5} lg={5}>
              <FeatureListContainer>
                {items.map((item, i) => (
                  <Fragment key={i}>
                    <FeatureList active={item.active}>
                      <FeatureListlink
                        active={item.active}
                        onClick={(e) => {
                          e.preventDefault();
                          onItemClick(i);
                        }}
                      >
                        {item.label}
                      </FeatureListlink>
                    </FeatureList>
                    <ListTextAnimation visible={item.active}>
                      <DescriptionText>{item.desc}</DescriptionText>
                      <Media when={"<=768"}>
                        <FeatureListImageMobile
                          src={item.imageUrl}
                          alt=""
                          visibleByDefault={true}
                        />
                      </Media>
                    </ListTextAnimation>
                  </Fragment>
                ))}
              </FeatureListContainer>
            </ColFeature>
            <Col xs={false} sm={7} md={7} lg={6} lgOffset={1}>
              <ImageContainer when={">768"}>
                {items.map((item, i) => (
                  <ListImageAnimation
                    noAnimation
                    key={i}
                    visible={item.active}
                    src={item.imageUrl}
                    alt=""
                  />
                ))}
              </ImageContainer>
            </Col>
          </>
        ) : (
          <>
            <Col xs={false} sm={7} md={7} lg={6}>
              <ImageContainer when={">768"}>
                {items.map((item, i) => (
                  <ListImageAnimation
                    key={i}
                    visible={item.active}
                    src={item.imageUrl}
                    alt=""
                  />
                ))}
              </ImageContainer>
            </Col>
            <ColFeature xs={12} sm={5} md={5} lg={5} lgOffset={1}>
              <FeatureListContainer>
                {items.map((item, i) => (
                  <Fragment key={i}>
                    <FeatureList active={item.active}>
                      <FeatureListlink
                        active={item.active}
                        onClick={(e) => {
                          e.preventDefault();
                          onItemClick(i);
                        }}
                      >
                        {item.label}
                      </FeatureListlink>
                    </FeatureList>
                    <ListTextAnimation visible={item.active}>
                      <DescriptionText>{item.desc}</DescriptionText>
                      <Media when={"<=768"}>
                        <FeatureListImageMobile
                          src={item.imageUrl}
                          alt=""
                          visibleByDefault={true}
                        />
                      </Media>
                    </ListTextAnimation>
                  </Fragment>
                ))}
                <FeatureList>
                  <Link
                    to="featuresMore"
                    spy={true}
                    smooth={true}
                    duration={700}
                    offset={-200}
                  >
                    <FeatureListlink>
                      <FormattedMessage
                        id="slider.andMore"
                        defaultMessage="And more"
                      />
                    </FeatureListlink>
                  </Link>
                </FeatureList>
              </FeatureListContainer>
            </ColFeature>
          </>
        )}
      </Row>
    </>
  );
};
